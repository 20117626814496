<template>
    <b-tabs class="m-0">
        <b-tab v-for="menuType in getMenuTypes()" :key="menuType">
            <template #title>
                <span class="font-semibold">{{ $t(`apps.configurator.menu-list.type-${menuType}`) }}</span>
            </template>
            <emptyAlert v-if="version.menus.filter(m => m.type === menuType).length === 0" :message="$t('apps.configurator.alert-empty.menu-text')" />
            <div v-else class="bg-white mt-4 p-8" >
                <SKCollapse>
                    <SKCollapseItem v-for="menu in sortMenus(version.menus.filter(m => m.type === menuType))" :key="menu.uuid" :ref="menu.uuid">
                        <template v-slot:header>
                            <MenuCollapseItemHeader :version="version" :menu="menu" :has-manage-buttons="isAdmin"/>
                        </template>
                        <template v-slot:content>
                            <MenuCollapseItemContent :version="version" :menu="menu" @nodeOpenChanged="changeCollapseItemHeight(menu.uuid)"/>
                        </template>
                    </SKCollapseItem>
                </SKCollapse>
            </div>
        </b-tab>
    </b-tabs>
</template>

<script>

import emptyAlert from "@/modules/Shared/Components/alert/emptyAlert.vue";
import {Version} from "@/modules/Apps/Models/Version";
import SKCollapse from "@/modules/Shared/Components/collapse/SKCollapse.vue";
import SKCollapseItem from "@/modules/Shared/Components/collapse/SKCollapseItem.vue";
import MenuCollapseItemHeader from "@/modules/Apps/Components/configurator/MenuCollapseItemHeader.vue";
import MenuCollapseItemContent from "@/modules/Apps/Components/configurator/MenuCollapseItemContent.vue";

export default {
    name: 'MenuTypeTabs',
    props: {
        version: {
            type: Version,
            required: true
        },
        isAdmin: {
            type: Boolean,
            default: false
        }
    },
    components: {
        MenuCollapseItemHeader,
        MenuCollapseItemContent,
        SKCollapseItem,
        SKCollapse,
        emptyAlert
    },
    methods: {
        changeCollapseItemHeight(menuUuid) {
            setTimeout(() => {
                this.$refs[menuUuid][0].changeHeight();
            }, 100)
        },
        getMenuTypes() {
            return this.isAdmin ? this.version.menuTypes() : ['home']
        },
        sortMenus(menus) {
            const orderedMenus = []
            menus
                .filter(menu => menu.is_default)
                .sort((a, b) => a.timeofyear > b.timeofyear ? -1 : 1)
                .forEach(menu => orderedMenus.push(menu))

            menus
                .filter(menu => !menu.is_default && menu.resorts.length === 0)
                .sort((a, b) => a.timeofyear > b.timeofyear ? -1 : 1)
                .forEach(menu => orderedMenus.push(menu))

            menus
                .filter(menu => !menu.is_default && menu.resorts.length > 1)
                .sort((a, b) => a.timeofyear > b.timeofyear ? -1 : 1)
                .forEach(menu => orderedMenus.push(menu))

            menus
                .filter(menu => !menu.is_default && menu.resorts.length === 1)
                .sort((a, b) => {
                    if (a.resortsNames === b.resortsNames) {
                        return a.timeofyear > b.timeofyear ? -1 : 1
                    }
                    return a.resortsNames > b.resortsNames ? 1 : -1
                })
                .forEach(menu => orderedMenus.push(menu))

            return orderedMenus
        },
    }
}
</script>
