var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.version
    ? _c(
        "div",
        [
          _c("ModalManageMenu", {
            ref: "modalManageMenu",
            attrs: { resorts: _vm.versionModel.application.resorts },
            on: {
              menuChanged: function(menu) {
                return _vm.addMenu(menu)
              }
            }
          }),
          _c("DatatablePageLayout", {
            scopedSlots: _vm._u(
              [
                {
                  key: "actionButtons",
                  fn: function() {
                    return [
                      _vm.isAdmin
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "xs:w-full sm:w-auto rounded-lg sk-btn sk-btn-primary",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.$refs["modalManageMenu"].open()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "apps.configurator.menu-list.create-menu"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "datatable",
                  fn: function() {
                    return [
                      _c("MenuTypeTabs", {
                        attrs: {
                          version: _vm.versionModel,
                          isAdmin: _vm.isAdmin
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2576811250
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }