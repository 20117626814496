import axios from '@/axios.js'

export default {
    getItemTemplateList: async (params, page = 1) => {
        try {
            const response = await axios.editorapi.get(`/v4/app-configurator/items-templates`, {
                params: {
                    ...params,
                    page: page
                }
            })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    }
}
