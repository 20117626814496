var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SKModal", {
    ref: "modal",
    attrs: {
      actionButtonsPosition: "right",
      hasAcceptButton: true,
      acceptCallback: _vm.cloneMenu,
      "accept-button-key": "copy-button-tooltip"
    },
    scopedSlots: _vm._u(
      [
        _vm.internalMenu
          ? {
              key: "body",
              fn: function() {
                return [
                  _vm.internalMenu.type === "home" && _vm.resorts.length > 1
                    ? _c("div", [
                        _c(
                          "div",
                          [
                            _c(
                              "vs-select",
                              {
                                staticClass: "w-full border-grey-light",
                                attrs: { placeholder: "Select resort/s" },
                                model: {
                                  value: _vm.internalMenu.resort,
                                  callback: function($$v) {
                                    _vm.$set(_vm.internalMenu, "resort", $$v)
                                  },
                                  expression: "internalMenu.resort"
                                }
                              },
                              _vm._l(_vm.resorts, function(resort) {
                                return _c("vs-select-item", {
                                  key: resort.uuid,
                                  attrs: {
                                    value: resort.uuid,
                                    text: resort.name
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _c("LabelForm", [
                        _vm._v(
                          _vm._s(_vm.$t("apps.configurator.manage-menu.name"))
                        )
                      ]),
                      _c("vs-input", {
                        staticClass: "w-full border-grey-light",
                        attrs: { type: "text", maxlength: "100" },
                        model: {
                          value: _vm.internalMenu.name,
                          callback: function($$v) {
                            _vm.$set(_vm.internalMenu, "name", $$v)
                          },
                          expression: "internalMenu.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("LabelForm", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("apps.configurator.manage-menu.timeofyear")
                          )
                        )
                      ]),
                      _c(
                        "vs-select",
                        {
                          staticClass: "w-50 border-grey-light",
                          model: {
                            value: _vm.internalMenu.timeofyear,
                            callback: function($$v) {
                              _vm.$set(_vm.internalMenu, "timeofyear", $$v)
                            },
                            expression: "internalMenu.timeofyear"
                          }
                        },
                        _vm._l(_vm.timeofyearOptions, function(option) {
                          return _c("vs-select-item", {
                            key: option.value,
                            attrs: { value: option.value, text: option.label }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }