<template>
    <div class="menu-preview">
        <div v-bind:style="getHeaderStyle()">
            <div v-if="history.length > 1" class="flex items-center justify-start">
                <img v-if="this.backArrowImageExists" :src="getBackArrowImageUrl()" class="cursor-pointer h-6 w-6" @click="goBack()"/>
                <feather-icon v-else v-bind:style="{color: this.fromArgbToRgba(this.styles.navigationbar_text_color)}" icon="ArrowLeftIcon" class="cursor-pointer" svgClasses="h-6 w-6 stroke-current" @click="goBack()"/>
            </div>
        </div>
        <div v-bind:style="getBodyStyle()">
            <div v-bind:style="getHomeStyle()">
                <div
                    v-for="item in current.items"
                    :key="item.uuid"
                    v-bind:style="getItemStyle(item)"
                    @click="onItemClick(item)"
                >
                    <div v-if="showItemText(item)" v-bind:style="getItemTextContainerStyle()">
                        <p v-bind:style="getItemTextStyle()">
                            {{ item.title[language] }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'menuPreview',
    props: {
        layout: { type: Object, required: true },
        styles: { type: Object, required: true },
        content: { type: Object, required: true },
        language: { type: String, required: true },
        timeofyear: { type: String, required: true },
        type: { type: String, required: true },
    },
    data() {
        return {
            history: [],
            size: 375,
            backArrowImageExists: false,
            fontName: null,
            resourcesBaseUrl: null
        }
    },
    watch: {
        content: function (content) {
            this.history = [content]
        }
    },
    computed: {
        current() {
            return this.history[this.history.length - 1]
        },
        ratio() {
            return this.size / 375
        }
    },
    async created() {
        this.history.push(this.content)
        this.resourcesBaseUrl = `https://appresources.skitude.com/${this.$route.params.uuid}/${this.$route.params.versionUuid}`
        this.backArrowImageExists = await this.checkBackArrowImage()
        await this.loadFont()
    },
    methods: {
        showItemText(item) {
            if (['banners', 'widget_resort_state', 'widget_webcams', 'single_data_widget'].includes(item.type)) {
                return false
            }

            if (!item.title || item.title.length === 0) {
                return false
            }

            if (!item.icon) {
                return true
            }

            return Object.values(item.icon).every( (val, i, arr) => val === arr[0] )
        },
        async loadFont() {
            this.fontName = this.styles.home_font;
            const fontUrl = `${this.resourcesBaseUrl}/delivery/fonts/${this.fontName}.ttf`
            const myFont = new FontFace(this.fontName, `url(${fontUrl})`);
            await myFont.load();
            document.fonts.add(myFont);
        },
        getBackgroundImageUrl() {
            const baseUrl = `${this.resourcesBaseUrl}/delivery/dynamic_Android_${this.timeofyear}/`
            let imageName;
            switch (this.type) {
                case 'home':
                    imageName = 'background_home'
                    break
                case 'selector':
                    imageName = 'background_resort_selector'
                    break
                case 'profile':
                    imageName = 'background_profile'
                    break
            }

            if (this.timeofyear === 'summer') {
                imageName = imageName + '_summer'
            }

            return `${baseUrl}${imageName}.png`
        },
        getBackArrowImageUrl() {
            return `${this.resourcesBaseUrl}/delivery/dynamic_Android_${this.timeofyear}/icon_navigationbar_return.png`
        },
        checkImageExists(imageUrl) {
            return new Promise((resolve, reject) => {
                let imageData = new Image();

                imageData.onload = function () {
                    resolve(true);
                };
                imageData.onerror = function () {
                    reject(false);
                };

                imageData.src = imageUrl;
            });
        },
        async checkBackArrowImage() {
            try {
                await this.checkImageExists(this.getBackArrowImageUrl())
                return true
            } catch (e) {
                return false
            }
        },
        goBack() {
            this.history.pop()
        },
        onItemClick(item) {
            if (item.submenu && item.submenu.type === 'home') {
                this.history.push(item.submenu)
            }
        },
        fromArgbToRgba(argb) {
            const hex = argb.toString();
            return `#${hex.slice(3, 5)}${hex.slice(5, 7)}${hex.slice(7, 9)}${hex.slice(1, 3)}`
        },
        getHeaderStyle() {
            return {
                'margin': '0 auto',
                'width': this.size + 'px',
                'background': this.fromArgbToRgba(this.styles.primary_color),
                'padding': 10 * this.ratio + 'px',
                'min-height': 60 * this.ratio + 'px',
                'display': 'flex',
                'justify-content': 'start',
                'align-items': 'center',
            }
        },
        getBodyStyle() {

            const style = {
                'margin': '0 auto',
                'width': this.size + 'px',
                'max-height': 590 * this.ratio + 'px',
                'height': 590 * this.ratio + 'px',
                'overflow-y': 'scroll',
                'overflow-x': 'hidden',
                'scrollbar-width': 'thin',
                'scrollbar-color': this.fromArgbToRgba(this.styles.primary_color) + ' transparent',
            }

            if (this.styles.background_home) {
                style['background-color'] = this.fromArgbToRgba(this.styles.background_home)
            } else {
                style['background-image'] = `url(${this.getBackgroundImageUrl()})`
                style['background-size'] = '100% 100%'
            }

            return style
        },
        getHomeStyle() {
            return {
                'width': this.size + 'px',
                'padding': this.getHomePadding() + 'px',
                'display': 'flex',
                'flex-wrap': 'wrap',
                'gap': this.getGapWidth() + 'px',
            }
        },
        getEffectiveWidth() {
            return (375 - (this.layout.padding * 2)) * this.ratio
        },
        getGapWidth() {
            return this.layout.spacing * this.ratio
        },
        getHomePadding() {
            return this.layout.padding * this.ratio
        },
        getColumnWith() {
            return (this.getEffectiveWidth() - ((this.getGapWidth() * (this.layout.columns_number - 1)))) / this.layout.columns_number
        },
        getWidthByColumns(columns) {
            return this.getColumnWith() * columns + this.getGapWidth() * (columns - 1)
        },
        getItemTextStyle() {
            return {
                'margin': '0',
                'text-align': this.styles.home_cell_text_align,
                'font-family': this.fontName,
                'font-size': this.styles.home_font_size * this.ratio + 'px',
                'color': this.fromArgbToRgba(this.styles.home_cell_text_color),
            }
        },
        getItemTextContainerStyle() {
            return {
                'max-height': this.layout.text_height * this.ratio + 'px',
                'margin-bottom': this.layout.text_bottom_margin * this.ratio + 'px',
                'padding-right': this.layout.text_lateral_margin * this.ratio + 'px',
                'padding-left': this.layout.text_lateral_margin * this.ratio + 'px',
            }
        },
        getItemStyle(item) {
            if (['banners', 'widget_resort_state', 'widget_webcams', 'single_data_widget'].includes(item.type)) {
                const paddingHorizontal = (this.layout.padding_horizontal_widget ? this.layout.padding_horizontal_widget : 0) * this.ratio
                const paddingVertical = (this.layout.padding_vertical_widget ? this.layout.padding_vertical_widget : 0) * this.ratio

                const width = this.getWidthByColumns(item.columns) - (2 * paddingHorizontal)
                const height = this.layout.height * this.ratio - (2 * paddingVertical)
                const imageText = item.title[this.language]
                const image = encodeURI(`https://placehold.co/${width}x${height}?text=${imageText}`)

                return {
                    'border-radius': this.layout.corner_radious_widget + 'px',
                    'margin': `${paddingVertical}px ${paddingHorizontal}px`,
                    'width': width + 'px',
                    'height': height + 'px',
                    'background-image': `url(${image})`,
                    'background-size': '100% 100%',
                }
            }

            const width = this.getWidthByColumns(item.columns)
            const height = this.layout.height * this.ratio

            const image = item.icon && item.icon[this.language]
                ? item.icon[this.language]
                : `https://placehold.co/${width}x${height}/red/white?text=${encodeURI(this.$t('apps.configurator.preview.image_missing'))}`

            return {
                'background-image': `url(${image})`,
                'background-size': '100% 100%',
                'width': width + 'px',
                'height': height + 'px',
                'cursor': item.submenu ? 'pointer' : 'default',
                'display': 'flex',
                'justify-content': 'center',
                'align-items': 'end',
            }
        },
    }
}
</script>
