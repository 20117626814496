export default {
    arePropertiesEmpty: (obj, properties = undefined) => {
        for (const key in obj) {
            if (properties && !properties.includes(key)) {
                continue
            }
            if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
                return false;
            }
        }
        return true;
    }
}