var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.internalItem
    ? _c(
        "div",
        [
          _c("ModalMenuItemGallery", {
            ref: "modalMenuItemGallery",
            attrs: { images: _vm.resources, selected: _vm.internalItem.icon },
            on: { select: _vm.handleSelectIcon }
          }),
          _c("div", [
            _vm.action === "create"
              ? _c("h3", [
                  _vm._v(_vm._s(_vm.$t("apps.configurator.manage-item.title")))
                ])
              : _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.internalItem.titles[
                        _vm.$store.state.AppActiveUser.language
                      ]
                    )
                  )
                ])
          ]),
          _c(
            "div",
            [
              _c("LabelForm", [
                _vm._v(_vm._s(_vm.$t("apps.configurator.manage-item.icon")))
              ]),
              _c(
                "div",
                { staticClass: "flex flex-row items-center gap-4" },
                [
                  _c("vs-input", {
                    staticClass: "input-modal w-50 border-grey-light",
                    attrs: { type: "text", maxlength: "50", disabled: "" },
                    model: {
                      value: _vm.internalItem.icon,
                      callback: function($$v) {
                        _vm.$set(_vm.internalItem, "icon", $$v)
                      },
                      expression: "internalItem.icon"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "flex flex-row items-center gap-2" },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "sk-btn sk-btn-small sk-btn-primary rounded-lg btn-border p-2",
                          on: { click: _vm.openGallery }
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "align-middle",
                            attrs: {
                              icon: "ImageIcon",
                              svgClasses: "h-6 w-6 stroke-current text-primary"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass:
                            "sk-btn sk-btn-small sk-btn-danger rounded-lg btn-border p-2",
                          on: { click: _vm.clearIcon }
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "align-middle",
                            attrs: {
                              icon: "XIcon",
                              svgClasses: "h-6 w-6 stroke-current text-danger"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.imagesObject
                ? _c(
                    "div",
                    [
                      _vm.imagesObject.multiLanguage &&
                      _vm.imagesObject.missingImages.length > 0
                        ? _c(
                            "Alert",
                            { staticClass: "mt-4", attrs: { type: "warning" } },
                            [
                              _c("p", { staticClass: "font-medium mb-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "apps.configurator.preview.image_missing"
                                    )
                                  ) + ":"
                                )
                              ]),
                              _c(
                                "ul",
                                _vm._l(_vm.imagesObject.missingImages, function(
                                  image
                                ) {
                                  return _c("li", { key: image }, [
                                    _vm._v(_vm._s(image))
                                  ])
                                }),
                                0
                              )
                            ]
                          )
                        : _vm._e(),
                      _c("Gallery", {
                        attrs: { "asset-list": _vm.imagesObject.images }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            [
              _c("LabelForm", [
                _vm._v(_vm._s(_vm.$t("apps.configurator.manage-item.flag")))
              ]),
              _c("vs-input", {
                staticClass: "input-modal w-full border-grey-light",
                attrs: {
                  disabled: _vm.isDisabled,
                  type: "text",
                  maxlength: "50"
                },
                model: {
                  value: _vm.internalItem.flag,
                  callback: function($$v) {
                    _vm.$set(_vm.internalItem, "flag", $$v)
                  },
                  expression: "internalItem.flag"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("LabelForm", [
                _vm._v(
                  _vm._s(_vm.$t("apps.configurator.manage-item.screen-name"))
                )
              ]),
              _c("vs-input", {
                staticClass: "input-modal w-full border-grey-light",
                attrs: {
                  disabled: _vm.isDisabled,
                  type: "text",
                  maxlength: "50"
                },
                model: {
                  value: _vm.internalItem.screen_name,
                  callback: function($$v) {
                    _vm.$set(_vm.internalItem, "screen_name", $$v)
                  },
                  expression: "internalItem.screen_name"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "flex flex-row flex-wrap" }, [
            _c(
              "div",
              { staticClass: "w-50 " },
              [
                _c("LabelForm", [
                  _vm._v(
                    _vm._s(_vm.$t("apps.configurator.manage-item.published"))
                  )
                ]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.internalItem.published,
                        expression: "internalItem.published"
                      }
                    ],
                    staticClass: "w-full border-grey-light input-modal",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.internalItem,
                          "published",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.publishedOpts, function(publishedOpt) {
                    return _c(
                      "option",
                      {
                        key: publishedOpt.value,
                        domProps: { value: publishedOpt.value }
                      },
                      [_vm._v(" " + _vm._s(publishedOpt.label) + " ")]
                    )
                  }),
                  0
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "w-50" },
            [
              _c("LabelForm", [
                _vm._v(_vm._s(_vm.$t("apps.configurator.manage-item.width")))
              ]),
              _c("vs-input", {
                staticClass: "input-modal w-full border-grey-light",
                attrs: {
                  disabled: _vm.menu.type === "tab",
                  type: "number",
                  min: "1"
                },
                model: {
                  value: _vm.internalItem.width,
                  callback: function($$v) {
                    _vm.$set(_vm.internalItem, "width", $$v)
                  },
                  expression: "internalItem.width"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "hidden" },
            [
              _c("LabelForm", [
                _vm._v(_vm._s(_vm.$t("apps.configurator.manage-item.section")))
              ]),
              _c("vs-input", {
                staticClass: "w-full border-grey-light input-modal",
                attrs: { disabled: true, type: "text", maxlength: "50" },
                model: {
                  value: _vm.internalItem.section,
                  callback: function($$v) {
                    _vm.$set(_vm.internalItem, "section", $$v)
                  },
                  expression: "internalItem.section"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("LabelForm", [
                _vm._v(
                  _vm._s(_vm.$t("apps.configurator.manage-item.item-template"))
                )
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.internalItem.item_template_uuid,
                      expression: "internalItem.item_template_uuid"
                    }
                  ],
                  staticClass: "w-full border-grey-light input-modal",
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.internalItem,
                          "item_template_uuid",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.changeTemplate
                    ]
                  }
                },
                _vm._l(_vm.templatesOpts, function(templatesOpt) {
                  return _c(
                    "option",
                    {
                      key: templatesOpt.value,
                      domProps: { value: templatesOpt.value }
                    },
                    [_vm._v(" " + _vm._s(templatesOpt.label) + " ")]
                  )
                }),
                0
              )
            ],
            1
          ),
          _vm.showConfiguration
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "mt-10" },
                    [
                      _c("LabelForm", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "apps.configurator.manage-item.configuration-templates-title"
                            )
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c("DynamicForm", {
                    attrs: {
                      languages: _vm.languages.map(function(lang) {
                        return lang.acronym
                      }),
                      columns: 1
                    },
                    on: { input: _vm.getDinamicInputsConfiguration },
                    model: {
                      value: _vm.configurationValue,
                      callback: function($$v) {
                        _vm.configurationValue = $$v
                      },
                      expression: "configurationValue"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "languages-inputs" },
            [
              _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _c("LabelForm", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "apps.configurator.manage-item.configuration-langs-titles"
                        )
                      )
                    )
                  ])
                ],
                1
              ),
              _c("DynamicForm", {
                attrs: {
                  languages: _vm.languages.map(function(lang) {
                    return lang.acronym
                  }),
                  columns: 2
                },
                on: { input: _vm.getDinamicInputsLanguages },
                model: {
                  value: _vm.languageValue,
                  callback: function($$v) {
                    _vm.languageValue = $$v
                  },
                  expression: "languageValue"
                }
              })
            ],
            1
          ),
          _c(
            "b-alert",
            {
              staticClass: "mt-5 rounded-lg",
              attrs: { show: _vm.errorsOnSave.length > 0, variant: "danger" }
            },
            [
              _c(
                "ul",
                { staticClass: "list-none" },
                _vm._l(_vm.errorsOnSave, function(errorString, index) {
                  return _c("li", { key: index }, [_vm._v(_vm._s(errorString))])
                }),
                0
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "action-item-button-container vx-col w-full mt-8" },
            [
              _c(
                "button",
                {
                  staticClass:
                    "xs:w-full sm:w-auto rounded-lg sk-btn sk-btn-primary btn-border mr-2",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.cancel.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("apps.configurator.menu-list.cancel")) +
                      " "
                  )
                ]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "xs:w-full sm:w-auto rounded-lg sk-btn sk-btn-primary",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.save.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("apps.configurator.menu-list.save")) +
                      " "
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }