export class Version {
    application = null
    version = null
    styles = []
    layouts = []
    menus = []
    languages = []
    templates = []
    resources = []
    customerResources = []

    constructor(application, version, styles, layouts, menus, languages, templates, resources, customerResources) {
        this.application = application
        this.version = version
        this.styles = styles
        this.layouts = layouts
        this.menus = menus
        this.languages = languages
        this.templates = templates
        this.resources = resources
        this.customerResources = customerResources
    }

    menuTypes() {
        return ['home', 'tab', 'selector', 'profile']
            .filter(type => [...new Set(this.menus.map(menu => menu.type))].includes(type))
    }

    mainLanguage() {
        return this.languages.find(language => language.is_default)
    }

    templatesByTimeofyearAndType(timeofyear, menuType) {
        return this.templates.filter(template => template.timeofyear === timeofyear && template.menu_type === menuType)
    }

    resourcesByPlatformAndTimeofyear(platform, timeofyear) {
        return this.resources[`dynamic-${platform}-${timeofyear}`].map(resource => 'https://appresources.skitude.com/' + resource)
    }
}
