var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-6 pb-2 pt-6 rounded bg-background-grey" },
    [
      _c("draggable-tree", {
        key: _vm.menu.uuid,
        attrs: {
          indent: 48,
          data: _vm.parsedItems,
          draggable: true,
          "cross-tree": false
        },
        on: {
          nodeOpenChanged: _vm.handleNodeOpenChanged,
          change: _vm.handleChange
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var data = ref.data
              var store = ref.store
              return _c(
                "div",
                {},
                [
                  !data.isDragPlaceHolder
                    ? _c("MenuTreeItem", {
                        attrs: { item: data, language: _vm.language },
                        on: {
                          toggle: function($event) {
                            return store.toggleOpen(data)
                          },
                          delete: _vm.deleteItem,
                          edit: _vm.editItem,
                          insertAfter: _vm.insertItemAfter,
                          insertInside: _vm.insertItemInside
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }