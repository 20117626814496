<template>
    <div :class="`px-4 py-3 rounded relative flex items-start gap-4 bg-${className}-light`">
        <feather-icon :icon="icon" :svgClasses="`h-6 w-6 mt-1 text-${className}`"/>
        <div :class="`text-${className}-dark`">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'alert',
    components: {},
    props: {
        type: {
            type: String,
            default: 'info',
            validator(value) {
                return ['info', 'success', 'warning', 'danger'].indexOf(value) !== -1
            }
        }
    },
    computed: {
        icon() {
            if (this.type === 'info') return 'InfoIcon'
            else if (this.type === 'success') return 'CheckIcon'
            else if (this.type === 'warning') return 'AlertTriangleIcon'
            else if (this.type === 'danger') return 'AlertCircleIcon'
        },
        className() {
            if (this.type === 'info') return 'primary'
            else if (this.type === 'success') return 'success'
            else if (this.type === 'warning') return 'warning'
            else if (this.type === 'danger') return 'danger'
        }
    }
}
</script>
