<template>
    <div class="flex flex-row justify-between py-4 flex-grow-1">
        <ModalManageMenu
            ref="modalManageMenu"
            :resorts="version.application.resorts"
            :menu="menu"
            @menuChanged="editMenu"
        />
        <ModalCloneMenu
            ref="modalCloneMenu"
            :resorts="version.application.resorts"
            :menu="menu"
            @cloned="handleCloneMenu"
        />
        <div class="menu-info-container pl-5 flex flex-row w-full justify-between items-center font-medium text-base leading-tight">
            <div class="w-1/2 flex flex-row justify-between items-center">
                <div class="w-1/2 menu-info-name-container">
                    <span class="text-lg">{{ menu.name }}</span>
                </div>
                <div class="w-1/2 menu-info-timeofyear-container">
                    <span>{{ menu.timeofyear }}</span>
                </div>
            </div>
            <div class="w-1/2 menu-info-resort-name-container">
                <span>{{ menu.resortsNames }}</span>
            </div>
        </div>
        <div v-if="hasManageButtons" class="menu-actions-container flex flex-row ml-4">
            <div class="menu-actions-edit-container">
                <button @click.stop="openEditMenuModal()" class="mr-2 rounded-lg sk-btn sk-btn-primary btn-border" >
                    <feather-icon icon="Edit2Icon" svgClasses="text-primary align-middle w-6" class="align-middle"/>
                </button>
            </div>
            <div class="menu-actions-clone-container">
                <button @click.stop="openCloneMenuModal()" class="mr-2 rounded-lg sk-btn sk-btn-primary btn-border" >
                    <feather-icon icon="CopyIcon" svgClasses="text-primary align-middle w-6" class="align-middle"/>
                </button>
            </div>
            <div class="menu-actions-delete-container">
                <button @click.stop="deleteMenuConfirmation" class="rounded-lg mr-2 sk-btn sk-btn-danger btn-border">
                    <feather-icon icon="TrashIcon" svgClasses="text-danger align-middle w-6" class="align-middle"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ConfiguratorMenuService from '@/modules/Apps/Services/Configurator/ConfiguratorMenuService'
import ModalManageMenu from "@/modules/Apps/Components/configurator/ModalManageMenu.vue";
import {Version} from "@/modules/Apps/Models/Version";
import loader from "@/modules/Shared/Mixins/loader";
import ModalCloneMenu from "@/modules/Apps/Components/configurator/ModalCloneMenu.vue";

export default {
    name: 'MenuCollapseItemHeader',
    components: {
        ModalCloneMenu,
        ModalManageMenu,
    },
    mixins: [loader],
    props: {
        version: {
            type: Version,
            required: true
        },
        menu: {
            type: Object,
            required: true
        },
        hasManageButtons: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        async deleteMenuConfirmation() {
            this.$vs.dialog({
                type:'confirm',
                color:'danger',
                title: this.$t("apps.configurator.menu-list.remove.title"),
                text: this.$t("apps.configurator.menu-list.remove.text"),
                accept: () => this.deleteMenu()
            })
        },
        async deleteMenu() {
            await this.loadAndNotify(async () => {
                await ConfiguratorMenuService.deleteMenu(this.menu.uuid)
                const menuIndex = this.version.menus.findIndex(m => m.uuid === this.menu.uuid)
                this.version.menus.splice(menuIndex, 1)
            })
        },
        openEditMenuModal() {
            this.$refs.modalManageMenu.open()
        },
        openCloneMenuModal() {
            this.$refs.modalCloneMenu.open()
        },
        editMenu(menu) {
            const menuIndex = this.version.menus.findIndex(m => m.uuid === menu.uuid)
            this.version.menus.splice(menuIndex, 1, menu)
        },
        async handleCloneMenu(menuUuid) {
            await this.loadAndNotify(async () => {
                const menu = await ConfiguratorMenuService.getMenu(menuUuid)
                this.version.menus.push({
                    ...menu,
                    resortsNames: this.version.application.resorts
                        .filter(resort => menu.resorts.includes(resort.uuid))
                        .map(resort => resort.name)
                        .join(', '),
                })
            }, true)
        }
    }
}
</script>
