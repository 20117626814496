var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-row p-8 max-w-lg mx-auto flex justify-center" },
    [
      _c("div", { staticClass: "w-full" }, [
        _c("div", { staticClass: "pt-4" }, [
          _c(
            "div",
            {
              staticClass:
                "create-item-container vx-col w-full mb-10 text-right"
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-row-reverse" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "xs:w-full sm:w-auto rounded-lg sk-btn sk-btn-primary",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.createItem.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("apps.configurator.menu-list.create-item")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _vm.menu.type !== "tab"
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "xs:w-full sm:w-auto rounded-lg sk-btn sk-btn-primary btn-border mr-2",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.previewMenu.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "align-middle",
                            attrs: {
                              icon: "EyeIcon",
                              svgClasses: "text-primary align-middle w-6"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.menu.items.length === 0
                    ? _c("emptyAlert", {
                        staticClass: "mr-5 flex-grow",
                        attrs: {
                          message: _vm.$t(
                            "apps.configurator.alert-empty.item-text"
                          )
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.action === "list",
                  expression: "action === 'list'"
                }
              ]
            },
            [
              _vm.menu.items.length > 0
                ? _c(
                    "div",
                    { staticClass: "vx-col w-full mb-10" },
                    [
                      _c("MenuTree", {
                        attrs: {
                          menu: _vm.menu,
                          language: _vm.version.mainLanguage()
                        },
                        on: {
                          editItem: _vm.editItem,
                          deleteItem: _vm.deleteItem,
                          insertItemAfter: _vm.insertItemAfter,
                          insertItemInside: _vm.insertItemInside,
                          nodeOpenChanged: function($event) {
                            return _vm.$emit("nodeOpenChanged")
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "action-item-button-container vx-col w-full" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "xs:w-full sm:w-auto rounded-lg sk-btn sk-btn-primary btn-border mr-2",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.cancel.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("apps.configurator.menu-list.cancel")) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "xs:w-full sm:w-auto rounded-lg sk-btn sk-btn-primary",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.save.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("apps.configurator.menu-list.save")) +
                          " "
                      )
                    ]
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.action === "form",
                  expression: "action === 'form'"
                }
              ]
            },
            [
              _c(
                "ManageMenuItemForm",
                _vm._b(
                  {
                    ref: "manageMenuItemForm",
                    attrs: {
                      languages: _vm.version.languages,
                      templates: _vm.version.templatesByTimeofyearAndType(
                        _vm.menu.timeofyear,
                        _vm.menu.type
                      ),
                      menu: _vm.menu,
                      resources: _vm.version.customerResources.concat(
                        _vm.version.resourcesByPlatformAndTimeofyear(
                          "android",
                          _vm.menu.timeofyear
                        )
                      )
                    },
                    on: {
                      change: function($event) {
                        _vm.action = "list"
                        _vm.$emit("nodeOpenChanged")
                      },
                      cancel: function($event) {
                        _vm.action = "list"
                        _vm.$emit("nodeOpenChanged")
                      },
                      loaded: function($event) {
                        return _vm.$emit("nodeOpenChanged")
                      }
                    }
                  },
                  "ManageMenuItemForm",
                  _vm.manageMenuItemFormProps,
                  false
                )
              )
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.action === "preview",
                  expression: "action === 'preview'"
                }
              ]
            },
            [
              _c("MenuPreviewSection", {
                ref: "menuPreviewSection",
                attrs: { version: _vm.version, menu: _vm.menu },
                on: {
                  cancel: function($event) {
                    _vm.action = "list"
                    _vm.$emit("nodeOpenChanged")
                  },
                  loaded: function($event) {
                    return _vm.$emit("nodeOpenChanged")
                  }
                }
              })
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }