var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SKModal", {
    ref: "modal",
    attrs: {
      fullscreen: true,
      actionButtonsPosition: "right",
      hasAcceptButton: false
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("vs-input", {
              staticClass: "input-modal w-1/3 border-grey-light",
              attrs: { type: "text", maxlength: "50" },
              model: {
                value: _vm.filter,
                callback: function($$v) {
                  _vm.filter = $$v
                },
                expression: "filter"
              }
            }),
            _c("Gallery", {
              attrs: {
                "asset-list": _vm.filteredImages,
                grouped: true,
                selected: _vm.selected
              },
              on: { select: _vm.handleSelect }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }