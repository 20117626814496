var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "relative item-container flex flex-row items-center rounded-lg bg-white",
      class: _vm.item.original.published ? "" : "inactive"
    },
    [
      _c("div", { staticClass: "add-row-container absolute hidden" }, [
        _c(
          "div",
          { staticClass: "flex gap-1" },
          [
            _c("feather-icon", {
              attrs: {
                icon: "PlusIcon",
                svgClasses:
                  "sk-btn sk-btn-primary w-5 h-5 rounded-full item-action-icon"
              },
              on: {
                click: function($event) {
                  return _vm.$emit("insertAfter", _vm.item)
                }
              }
            }),
            _vm.isSubmenu()
              ? _c("feather-icon", {
                  attrs: {
                    icon: "CornerDownRightIcon",
                    svgClasses:
                      "sk-btn sk-btn-primary w-5 h-5 rounded-full item-action-icon"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("insertInside", _vm.item)
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "item-description-container flex flex-1 items-center" },
        [
          _c(
            "div",
            { staticClass: "item-description-icon-container px-4" },
            [
              _c("feather-icon", {
                staticClass: "align-middle",
                attrs: {
                  icon: "MenuIcon",
                  svgClasses: "inactive align-middle w-6"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "flex flex-column" }, [
            _c(
              "div",
              { staticClass: "item-description-name-container" },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "item-description-name font-semibold text-base leading-tight "
                  },
                  [_vm._v(_vm._s(_vm.itemName()))]
                ),
                _vm.isSubmenu() && _vm.item.children.length > 0 && _vm.item.open
                  ? _c("feather-icon", {
                      staticClass: "align-middle ml-1",
                      attrs: {
                        icon: "ChevronDownIcon",
                        svgClasses: "inactive align-middle w-5"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("toggle")
                        }
                      }
                    })
                  : _vm._e(),
                _vm.isSubmenu() &&
                _vm.item.children.length > 0 &&
                !_vm.item.open
                  ? _c("feather-icon", {
                      staticClass: "align-middle ml-1",
                      attrs: {
                        icon: "ChevronRightIcon",
                        svgClasses: "inactive align-middle w-5"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("toggle")
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm.isSubmenu()
              ? _c(
                  "div",
                  {
                    staticClass:
                      "item-description-has-childs-container font-thin leading-none"
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "item-description-has-childs text-xs inactive"
                      },
                      [_vm._v("submenu")]
                    )
                  ]
                )
              : _vm._e()
          ])
        ]
      ),
      _c("div", { staticClass: "item-actions-container flex p-2" }, [
        _c("div", { staticClass: "item-action-edit-container" }, [
          _c(
            "button",
            {
              staticClass:
                "mr-2 py-1 px-2 rounded-lg sk-btn sk-btn-primary btn-border",
              on: {
                click: function($event) {
                  return _vm.$emit("edit", _vm.item)
                }
              }
            },
            [
              _c("feather-icon", {
                staticClass: "align-middle",
                attrs: {
                  icon: "Edit2Icon",
                  svgClasses: "text-primary align-middle w-5"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "item-action-delete-container" }, [
          _c(
            "button",
            {
              staticClass:
                "rounded-lg px-2 py-1 mr-2 sk-btn sk-btn-danger btn-border",
              on: { click: _vm.remove }
            },
            [
              _c("feather-icon", {
                staticClass: "align-middle",
                attrs: {
                  icon: "TrashIcon",
                  svgClasses: "text-danger align-middle w-5"
                }
              })
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }