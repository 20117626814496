var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "menu-preview" }, [
    _c("div", { style: _vm.getHeaderStyle() }, [
      _vm.history.length > 1
        ? _c(
            "div",
            { staticClass: "flex items-center justify-start" },
            [
              this.backArrowImageExists
                ? _c("img", {
                    staticClass: "cursor-pointer h-6 w-6",
                    attrs: { src: _vm.getBackArrowImageUrl() },
                    on: {
                      click: function($event) {
                        return _vm.goBack()
                      }
                    }
                  })
                : _c("feather-icon", {
                    staticClass: "cursor-pointer",
                    style: {
                      color: this.fromArgbToRgba(
                        this.styles.navigationbar_text_color
                      )
                    },
                    attrs: {
                      icon: "ArrowLeftIcon",
                      svgClasses: "h-6 w-6 stroke-current"
                    },
                    on: {
                      click: function($event) {
                        return _vm.goBack()
                      }
                    }
                  })
            ],
            1
          )
        : _vm._e()
    ]),
    _c("div", { style: _vm.getBodyStyle() }, [
      _c(
        "div",
        { style: _vm.getHomeStyle() },
        _vm._l(_vm.current.items, function(item) {
          return _c(
            "div",
            {
              key: item.uuid,
              style: _vm.getItemStyle(item),
              on: {
                click: function($event) {
                  return _vm.onItemClick(item)
                }
              }
            },
            [
              _vm.showItemText(item)
                ? _c("div", { style: _vm.getItemTextContainerStyle() }, [
                    _c("p", { style: _vm.getItemTextStyle() }, [
                      _vm._v(" " + _vm._s(item.title[_vm.language]) + " ")
                    ])
                  ])
                : _vm._e()
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }