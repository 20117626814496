<template>
    <div :class="item.original.published ? '' : 'inactive'" class="relative item-container flex flex-row items-center rounded-lg bg-white">
        <div class="add-row-container absolute hidden">
            <div class="flex gap-1">
                <feather-icon @click="$emit('insertAfter', item)" icon="PlusIcon" svgClasses="sk-btn sk-btn-primary w-5 h-5 rounded-full item-action-icon"/>
                <feather-icon @click="$emit('insertInside', item)" v-if="isSubmenu()" icon="CornerDownRightIcon" svgClasses="sk-btn sk-btn-primary w-5 h-5 rounded-full item-action-icon"/>
            </div>
        </div>
        <div class="item-description-container flex flex-1 items-center">
            <div class="item-description-icon-container px-4">
                <feather-icon icon="MenuIcon" svgClasses="inactive align-middle w-6" class="align-middle" />
            </div>
            <div class="flex flex-column">
                <div class="item-description-name-container">
                    <span class="item-description-name font-semibold text-base leading-tight ">{{ itemName() }}</span>
                    <feather-icon v-if="isSubmenu() && item.children.length > 0 && item.open" icon="ChevronDownIcon" svgClasses="inactive align-middle w-5" class="align-middle ml-1" @click="$emit('toggle')"/>
                    <feather-icon v-if="isSubmenu() && item.children.length > 0 && !item.open" icon="ChevronRightIcon" svgClasses="inactive align-middle w-5" class="align-middle ml-1" @click="$emit('toggle')"/>
                </div>
                <div v-if="isSubmenu()" class="item-description-has-childs-container font-thin leading-none">
                    <span class="item-description-has-childs text-xs inactive">submenu</span>
                </div>
            </div>
        </div>
        <div class="item-actions-container flex p-2">
            <div class="item-action-edit-container">
                <button @click="$emit('edit', item)" class="mr-2 py-1 px-2 rounded-lg sk-btn sk-btn-primary btn-border">
                    <feather-icon icon="Edit2Icon" svgClasses="text-primary align-middle w-5" class="align-middle"/>
                </button>
            </div>
            <div class="item-action-delete-container">
                <button @click="remove" class="rounded-lg px-2 py-1 mr-2 sk-btn sk-btn-danger btn-border">
                    <feather-icon icon="TrashIcon" svgClasses="text-danger align-middle w-5" class="align-middle"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'MenuTreeItem',
    props: {
        item: {
            type: Object,
            required: true
        },
        language: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            clicked: false
        }
    },
    methods: {
        isSubmenu() {
            return this.item.original.item_template_uuid === '69771ad4-fe34-11ed-bca0-027fea2f4c28' ||
                this.item.original.item_template_uuid === 'f2e4ff74-fecf-11ed-aae8-02506e7ea1f4'
        },
        itemName() {
            return this.item.original.titles[this.language.acronym]
        },
        remove() {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: this.$t("shops.delete"),
                text: this.$t("shops.are_you_sure_about_that?"),
                accept: () => this.$emit("delete", this.item)
            })
        },
    },
}
</script>

<style scoped>
.inactive {
    color: rgb(176, 184, 194);
}

.item-container:hover  .add-row-container{
    display: block !important;
}

.add-row-container {
    margin-bottom: -45px;
    margin-left: -10px;
}
.item-action-icon {
    padding: 3px;
}
</style>
