var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-row justify-between py-4 flex-grow-1" },
    [
      _c("ModalManageMenu", {
        ref: "modalManageMenu",
        attrs: { resorts: _vm.version.application.resorts, menu: _vm.menu },
        on: { menuChanged: _vm.editMenu }
      }),
      _c("ModalCloneMenu", {
        ref: "modalCloneMenu",
        attrs: { resorts: _vm.version.application.resorts, menu: _vm.menu },
        on: { cloned: _vm.handleCloneMenu }
      }),
      _c(
        "div",
        {
          staticClass:
            "menu-info-container pl-5 flex flex-row w-full justify-between items-center font-medium text-base leading-tight"
        },
        [
          _c(
            "div",
            { staticClass: "w-1/2 flex flex-row justify-between items-center" },
            [
              _c("div", { staticClass: "w-1/2 menu-info-name-container" }, [
                _c("span", { staticClass: "text-lg" }, [
                  _vm._v(_vm._s(_vm.menu.name))
                ])
              ]),
              _c(
                "div",
                { staticClass: "w-1/2 menu-info-timeofyear-container" },
                [_c("span", [_vm._v(_vm._s(_vm.menu.timeofyear))])]
              )
            ]
          ),
          _c("div", { staticClass: "w-1/2 menu-info-resort-name-container" }, [
            _c("span", [_vm._v(_vm._s(_vm.menu.resortsNames))])
          ])
        ]
      ),
      _vm.hasManageButtons
        ? _c(
            "div",
            { staticClass: "menu-actions-container flex flex-row ml-4" },
            [
              _c("div", { staticClass: "menu-actions-edit-container" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "mr-2 rounded-lg sk-btn sk-btn-primary btn-border",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.openEditMenuModal()
                      }
                    }
                  },
                  [
                    _c("feather-icon", {
                      staticClass: "align-middle",
                      attrs: {
                        icon: "Edit2Icon",
                        svgClasses: "text-primary align-middle w-6"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "menu-actions-clone-container" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "mr-2 rounded-lg sk-btn sk-btn-primary btn-border",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.openCloneMenuModal()
                      }
                    }
                  },
                  [
                    _c("feather-icon", {
                      staticClass: "align-middle",
                      attrs: {
                        icon: "CopyIcon",
                        svgClasses: "text-primary align-middle w-6"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "menu-actions-delete-container" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "rounded-lg mr-2 sk-btn sk-btn-danger btn-border",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.deleteMenuConfirmation.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("feather-icon", {
                      staticClass: "align-middle",
                      attrs: {
                        icon: "TrashIcon",
                        svgClasses: "text-danger align-middle w-6"
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }