var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SKModal", {
    ref: "modal",
    attrs: {
      actionButtonsPosition: "right",
      hasAcceptButton: true,
      acceptCallback: _vm.saveMenu
    },
    scopedSlots: _vm._u(
      [
        _vm.internalMenu
          ? {
              key: "body",
              fn: function() {
                return [
                  _vm.internalMenu.type === "home" && _vm.resorts.length > 1
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "mb-5" },
                          [
                            _c("LabelForm", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "apps.configurator.manage-menu.resorts"
                                  )
                                )
                              )
                            ]),
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "ml-0 mt-3 mb-3",
                                attrs: {
                                  "v-model": _vm.internalMenu.is_default
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.checkAllResorts()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "apps.configurator.manage-menu.all-resorts"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        !_vm.internalMenu.is_default
                          ? _c(
                              "div",
                              [
                                _c(
                                  "vs-select",
                                  {
                                    staticClass: "w-full border-grey-light",
                                    attrs: {
                                      placeholder: "Select resort/s",
                                      multiple: ""
                                    },
                                    model: {
                                      value: _vm.internalMenu.resorts,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.internalMenu,
                                          "resorts",
                                          $$v
                                        )
                                      },
                                      expression: "internalMenu.resorts"
                                    }
                                  },
                                  [
                                    _c("vs-select-item", {
                                      key: "-- Select resort/s --",
                                      attrs: {
                                        value: "null",
                                        text: "-- Select resort/s --",
                                        disabled: true
                                      }
                                    }),
                                    _vm._l(_vm.resorts, function(resort) {
                                      return _c("vs-select-item", {
                                        key: resort.uuid,
                                        attrs: {
                                          value: resort.uuid,
                                          text: resort.name
                                        }
                                      })
                                    })
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _c("LabelForm", [
                        _vm._v(
                          _vm._s(_vm.$t("apps.configurator.manage-menu.name"))
                        )
                      ]),
                      _c("vs-input", {
                        staticClass: "w-full border-grey-light",
                        attrs: { type: "text", maxlength: "100" },
                        model: {
                          value: _vm.internalMenu.name,
                          callback: function($$v) {
                            _vm.$set(_vm.internalMenu, "name", $$v)
                          },
                          expression: "internalMenu.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("LabelForm", [
                        _vm._v(
                          _vm._s(_vm.$t("apps.configurator.manage-menu.type"))
                        )
                      ]),
                      _c(
                        "vs-select",
                        {
                          staticClass: "w-50 border-grey-light",
                          model: {
                            value: _vm.internalMenu.type,
                            callback: function($$v) {
                              _vm.$set(_vm.internalMenu, "type", $$v)
                            },
                            expression: "internalMenu.type"
                          }
                        },
                        _vm._l(_vm.typesOptions, function(option) {
                          return _c("vs-select-item", {
                            key: option.value,
                            attrs: { value: option.value, text: option.label }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("LabelForm", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("apps.configurator.manage-menu.timeofyear")
                          )
                        )
                      ]),
                      _c(
                        "vs-select",
                        {
                          staticClass: "w-50 border-grey-light",
                          model: {
                            value: _vm.internalMenu.timeofyear,
                            callback: function($$v) {
                              _vm.$set(_vm.internalMenu, "timeofyear", $$v)
                            },
                            expression: "internalMenu.timeofyear"
                          }
                        },
                        _vm._l(_vm.timeofyearOptions, function(option) {
                          return _c("vs-select-item", {
                            key: option.value,
                            attrs: { value: option.value, text: option.label }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-alert",
                    {
                      staticClass: "my-5 rounded-lg",
                      attrs: {
                        show: _vm.errorsOnSave.length > 0,
                        variant: "danger"
                      }
                    },
                    [
                      _c(
                        "ul",
                        { staticClass: "list-none" },
                        _vm._l(_vm.errorsOnSave, function(errorString, index) {
                          return _c("li", { key: index }, [
                            _vm._v(_vm._s(errorString))
                          ])
                        }),
                        0
                      )
                    ]
                  )
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }