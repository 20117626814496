<template>
    <SKModal
        ref="modal"
        actionButtonsPosition="right"
        :hasAcceptButton="true"
        :acceptCallback="cloneMenu"
        accept-button-key="copy-button-tooltip"
    >
        <template v-slot:body v-if="internalMenu">
            <div v-if="internalMenu.type === 'home' && resorts.length > 1">
                <div>
                    <vs-select placeholder="Select resort/s" class="w-full border-grey-light" v-model="internalMenu.resort">
                        <vs-select-item :key="resort.uuid" :value="resort.uuid" :text="resort.name"
                                        v-for="resort in resorts" />
                    </vs-select>
                </div>
            </div>
            <div>
                <LabelForm>{{ $t('apps.configurator.manage-menu.name') }}</LabelForm>
                <vs-input type="text" maxlength="100" class="w-full border-grey-light" v-model="internalMenu.name" />
            </div>
            <div>
                <LabelForm>{{ $t('apps.configurator.manage-menu.timeofyear') }}</LabelForm>
                <vs-select class="w-50 border-grey-light" v-model="internalMenu.timeofyear">
                    <vs-select-item :key="option.value" :value="option.value" :text="option.label"
                                    v-for="option in timeofyearOptions" />
                </vs-select>
            </div>
        </template>
    </SKModal>
</template>
<script>

import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import SKModal from "@/modules/Shared/Components/modal/SKModal";
import { v4 as uuidv4 } from 'uuid';
import ConfiguratorMenuService from "@/modules/Apps/Services/Configurator/ConfiguratorMenuService";
import loader from "@/modules/Shared/Mixins/loader";

export default {
    name: 'ModalCloneMenu',
    mixins: [loader],
    props: {
        menu: {
            type: Object,
            required: false
        },
        resorts: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            internalMenu: null,
            timeofyearOptions: [
                { label: this.$t('apps.configurator.manage-menu.winter'), value: "winter" },
                { label: this.$t('apps.configurator.manage-menu.summer'), value: "summer" },
            ],
        }
    },
    components: {
        LabelForm,
        SKModal,
    },
    methods: {
        async open() {
            this.initialize()
            this.$refs['modal'].open()
        },
        initialize() {
            this.internalMenu = JSON.parse(JSON.stringify(this.menu))
            this.internalMenu.name = ''
            this.internalMenu.resort = null
            if (this.internalMenu.type === 'home') {
                this.internalMenu.resort = this.resorts[0].uuid
            }
        },
        async cloneMenu() {
            await this.loadAndNotify(async () => {
                const newMenuUuid = uuidv4()
                let payload = {
                    version_uuid: this.$route.params.versionUuid,
                    timeofyear: this.internalMenu.timeofyear,
                    from: this.internalMenu.uuid
                }

                if (this.internalMenu.name !== '') {
                    payload.name = this.internalMenu.name
                }

                if (this.internalMenu.resort) {
                    payload.resort_uuid = this.internalMenu.resort
                }

                await ConfiguratorMenuService.createMenu(newMenuUuid, payload)

                this.$emit("cloned", newMenuUuid)
            })
        },
    },
}
</script>
