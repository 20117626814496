var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "px-4 py-3 rounded relative flex items-start gap-4 bg-" +
        _vm.className +
        "-light"
    },
    [
      _c("feather-icon", {
        attrs: {
          icon: _vm.icon,
          svgClasses: "h-6 w-6 mt-1 text-" + _vm.className
        }
      }),
      _c(
        "div",
        { class: "text-" + _vm.className + "-dark" },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }