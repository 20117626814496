<template>
    <SKModal
        ref="modal"
        actionButtonsPosition="right"
        :hasAcceptButton="true"
        :acceptCallback="saveMenu"
    >
        <template v-slot:body v-if="internalMenu">
            <div v-if="internalMenu.type === 'home' && resorts.length > 1">
                <div class="mb-5">
                    <LabelForm>{{ $t('apps.configurator.manage-menu.resorts') }}</LabelForm>
                    <vs-checkbox
                        class="ml-0 mt-3 mb-3"
                        :v-model="internalMenu.is_default"
                        @input="checkAllResorts()"
                    >
                        {{ $t('apps.configurator.manage-menu.all-resorts') }}
                    </vs-checkbox>
                </div>
                <div v-if="!internalMenu.is_default">
                    <vs-select placeholder="Select resort/s" class="w-full border-grey-light" v-model="internalMenu.resorts" multiple>
                        <vs-select-item key='-- Select resort/s --' value="null" text="-- Select resort/s --" :disabled="true"/>
                        <vs-select-item :key="resort.uuid" :value="resort.uuid" :text="resort.name"
                                        v-for="resort in resorts" />
                    </vs-select>
                </div>
            </div>
            <div>
                <LabelForm>{{ $t('apps.configurator.manage-menu.name') }}</LabelForm>
                <vs-input type="text" maxlength="100" class="w-full border-grey-light" v-model="internalMenu.name" />
            </div>
            <div>
                <LabelForm>{{ $t('apps.configurator.manage-menu.type') }}</LabelForm>
                <vs-select class="w-50 border-grey-light" v-model="internalMenu.type">
                    <vs-select-item :key="option.value" :value="option.value" :text="option.label"
                                    v-for="option in typesOptions" />
                </vs-select>
            </div>
            <div>
                <LabelForm>{{ $t('apps.configurator.manage-menu.timeofyear') }}</LabelForm>
                <vs-select class="w-50 border-grey-light" v-model="internalMenu.timeofyear">
                    <vs-select-item :key="option.value" :value="option.value" :text="option.label"
                                    v-for="option in timeofyearOptions" />
                </vs-select>
            </div>
            <b-alert :show="errorsOnSave.length > 0" variant="danger" class="my-5 rounded-lg">
                <ul class="list-none">
                    <li v-for="(errorString, index) in errorsOnSave" :key="index">{{ errorString }}</li>
                </ul>
            </b-alert>
        </template>
    </SKModal>
</template>
<script>

import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import SKModal from "@/modules/Shared/Components/modal/SKModal";
import { v4 as uuidv4 } from 'uuid';
import ConfiguratorMenuService from "@/modules/Apps/Services/Configurator/ConfiguratorMenuService";
import loader from "@/modules/Shared/Mixins/loader";

export default {
    name: 'ModalManageMenu',
    mixins: [loader],
    props: {
        menu: {
            type: Object,
            required: false
        },
        resorts: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            internalMenu: null,
            action: 'update',
            typesOptions: [
                { label: this.$t('apps.configurator.manage-menu.type-home'), value: "home" },
                { label: this.$t('apps.configurator.manage-menu.type-tab'), value: "tab" },
                { label: this.$t('apps.configurator.manage-menu.type-selector'), value: "selector" },
                { label: this.$t('apps.configurator.manage-menu.type-profile'), value: "profile" },
            ],
            timeofyearOptions: [
                { label: this.$t('apps.configurator.manage-menu.winter'), value: "winter" },
                { label: this.$t('apps.configurator.manage-menu.summer'), value: "summer" },
            ],
            errorsOnSave: [],
        }
    },
    components: {
        LabelForm,
        SKModal,
    },
    methods: {
        async open() {
            this.initialize()
            this.$refs['modal'].open()
        },
        initialize() {
            if (!this.menu) {
                this.action = 'create'
                this.internalMenu = {
                    uuid: uuidv4(),
                    name: '',
                    type: 'home',
                    timeofyear: 'winter',
                    resorts: [],
                    items: [],
                    is_default: false
                }
            } else {
                this.internalMenu = JSON.parse(JSON.stringify(this.menu))
            }
        },
        hasErrors() {
            this.errorsOnSave = []

            if (!this.internalMenu.name) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t('apps.configurator.manage-menu.error-name')]
            }

            if (!this.internalMenu.type) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t('apps.configurator.manage-menu.error-type')]
            }

            if (!this.internalMenu.timeofyear) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t('apps.configurator.manage-menu.error-timeofyear')]
            }

            return this.errorsOnSave.length !== 0
        },
        checkAllResorts() {
            this.internalMenu.resorts = this.resorts
            this.internalMenu.is_default = !this.internalMenu.is_default
        },
        async createMenu() {
            let newMenu = {
                version_uuid: this.$route.params.versionUuid,
                name: this.internalMenu.name,
                type: this.internalMenu.type,
                timeofyear: this.internalMenu.timeofyear
            }
            await ConfiguratorMenuService.createMenu(this.internalMenu.uuid, newMenu)
            await ConfiguratorMenuService.editMenu(this.internalMenu.uuid, {
                ... newMenu,
                resorts: this.getSelectedResorts(),
                items: [],
                is_default: this.internalMenu.is_default
            })
        },
        async editMenu() {
            await ConfiguratorMenuService.editMenu(this.internalMenu.uuid, {
                name: this.internalMenu.name,
                type: this.internalMenu.type,
                timeofyear: this.internalMenu.timeofyear,
                resorts: this.getSelectedResorts(),
                items: typeof this.internalMenu.items === "undefined" ? [] : this.internalMenu.items,
                is_default: this.internalMenu.is_default
            })
        },
        getSelectedResorts() {
            if (this.internalMenu.is_default) {
                return []
            }
            if (this.resorts.length === 1) {
                return [this.resorts[0].uuid]
            }
            return this.internalMenu.resorts.map((r) => r.uuid)
        },
        async saveMenu() {
            if (!this.hasErrors()) {
                await this.loadAndNotify(async () => {
                    if (this.action === 'create') {
                        await this.createMenu()
                    } else {
                        await this.editMenu()
                    }

                    this.$emit("menuChanged", this.internalMenu)
                })
            }
        },
    },
}
</script>
