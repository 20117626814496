<template>
    <SKModal
        ref="modal"
        :fullscreen="true"
        actionButtonsPosition="right"
        :hasAcceptButton="false"
    >
        <template v-slot:body>
            <vs-input type="text" maxlength="50"
                      class="input-modal w-1/3 border-grey-light"
                      v-model="filter"
            />
            <Gallery :asset-list="filteredImages" :grouped="true" @select="handleSelect" :selected="selected"/>
        </template>
    </SKModal>
</template>
<script>

import SKModal from "@/modules/Shared/Components/modal/SKModal";
import Gallery from "@/modules/Apps/Components/resources/gallery/Gallery.vue";

export default {
    name: 'ModalMenuItemGallery',
    props: {
        images: {
            type: Array,
            required: true
        },
        selected: {
            type: String,
            default: '',
            required: false
        }
    },
    data() {
        return {
            filter: '',
        }
    },
    components: {
        Gallery,
        SKModal,
    },
    computed: {
        filteredImages() {
            if (!this.filter) {
                return this.images
            }

            return this.images.filter((image) => {
                const name = image.split('/').pop().split('.')[0]
                return name.toLowerCase().includes(this.filter.toLowerCase())
            })
        }
    },
    methods: {
        async open() {
            this.filter = ''
            this.$refs['modal'].open()
        },
        handleSelect(asset) {
            this.$emit('select', asset)
            this.$refs['modal'].close()
        },
    },
}
</script>
