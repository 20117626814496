var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-tabs",
    { staticClass: "m-0" },
    _vm._l(_vm.getMenuTypes(), function(menuType) {
      return _c(
        "b-tab",
        {
          key: menuType,
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function() {
                  return [
                    _c("span", { staticClass: "font-semibold" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("apps.configurator.menu-list.type-" + menuType)
                        )
                      )
                    ])
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        [
          _vm.version.menus.filter(function(m) {
            return m.type === menuType
          }).length === 0
            ? _c("emptyAlert", {
                attrs: {
                  message: _vm.$t("apps.configurator.alert-empty.menu-text")
                }
              })
            : _c(
                "div",
                { staticClass: "bg-white mt-4 p-8" },
                [
                  _c(
                    "SKCollapse",
                    _vm._l(
                      _vm.sortMenus(
                        _vm.version.menus.filter(function(m) {
                          return m.type === menuType
                        })
                      ),
                      function(menu) {
                        return _c("SKCollapseItem", {
                          key: menu.uuid,
                          ref: menu.uuid,
                          refInFor: true,
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function() {
                                  return [
                                    _c("MenuCollapseItemHeader", {
                                      attrs: {
                                        version: _vm.version,
                                        menu: menu,
                                        "has-manage-buttons": _vm.isAdmin
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "content",
                                fn: function() {
                                  return [
                                    _c("MenuCollapseItemContent", {
                                      attrs: {
                                        version: _vm.version,
                                        menu: menu
                                      },
                                      on: {
                                        nodeOpenChanged: function($event) {
                                          return _vm.changeCollapseItemHeight(
                                            menu.uuid
                                          )
                                        }
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      }
                    ),
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }