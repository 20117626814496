<template>
    <div>
        <emptyAlert class="mb-4" :message="$t('apps.configurator.preview.disclaimer')" />
        <div class="flex justify-center items-center mb-4">
            <vs-select v-if="languages.length > 1" class="border-grey-light w-full md:w-1/2" v-model="selectedLanguage" required>
                <vs-select-item :key="language.acronym" :value="language.acronym" :text="language.name" v-for="language in languages"/>
            </vs-select>
        </div>

        <MenuPreview
            v-if="loaded && content && layout && styles"
            :content="content"
            :layout="layout"
            :styles="styles"
            :language="selectedLanguage"
            :timeofyear="menu.timeofyear"
            :type="menu.type"
        />

        <div class="action-item-button-container vx-col w-full mt-8">
            <button @click.stop="$emit('cancel')" class="xs:w-full sm:w-auto rounded-lg sk-btn sk-btn-primary btn-border mr-2">
                {{ $t("apps.configurator.menu-list.cancel") }}
            </button>
        </div>
    </div>
</template>

<script>
import SKModal from "@/modules/Shared/Components/modal/SKModal";
import MenuPreview from "@/modules/Apps/Pages/configurator/components/MenuPreview.vue";
import emptyAlert from "@/modules/Shared/Components/alert/emptyAlert.vue";
import ConfiguratorMenuService from "@/modules/Apps/Services/Configurator/ConfiguratorMenuService";
import {Version} from "@/modules/Apps/Models/Version";
import loader from "@/modules/Shared/Mixins/loader";

export default {
    name: 'MenuPreviewSection',
    mixins: [
        loader
    ],
    props: {
        menu: {
            type: Object,
            required: false
        },
        version: {
            type: Version,
            required: true
        }
    },
    data() {
        return {
            loaded: false,
            styles: null,
            layout: null,
            content: null,
            languages: [],
            selectedLanguage: null
        }
    },
    components: {
        emptyAlert,
        MenuPreview,
        SKModal,
    },
    watch: {
        'menu': async function() {
            await this.reload()
        }
    },
    methods: {
        async open() {
            await this.reload()
        },
        async reload() {
            await this.load(async () => {
                this.loaded = false

                this.languages = this.version.languages
                this.selectedLanguage = this.languages[0].acronym
                this.styles = this.version.styles.find(style => style.timeOfYear === this.menu.timeofyear).values;
                this.layout = this.version.layouts.find(layout => layout.type === this.menu.type).values
                this.content = await ConfiguratorMenuService.getMenuPreview(this.menu.uuid)

                this.loaded = true
                this.$emit('loaded')
            });
        }
    }
}
</script>
