<template>
    <div v-if="version">
        <ModalManageMenu
            ref="modalManageMenu"
            :resorts="versionModel.application.resorts"
            @menuChanged="(menu) => addMenu(menu)"
        />
        <DatatablePageLayout>
            <template v-slot:actionButtons>
                <button v-if="isAdmin" @click.stop="$refs['modalManageMenu'].open()" class="xs:w-full sm:w-auto rounded-lg sk-btn sk-btn-primary">
                    {{ $t("apps.configurator.menu-list.create-menu") }}
                </button>
            </template>
            <template v-slot:datatable>
                <MenuTypeTabs :version="versionModel" :isAdmin="isAdmin"/>
            </template>
        </DatatablePageLayout>
    </div>
</template>

<script>
import ConfiguratorMenuService from '@/modules/Apps/Services/Configurator/ConfiguratorMenuService'
import PaginationService from '@/modules/Shared/Services/PaginationService'
import ConfiguratorItemTemplateService from '@/modules/Apps/Services/Configurator/ConfiguratorItemTemplateService'
import loader from "@/modules/Shared/Mixins/loader";
import AppsService from "@/modules/Apps/Services/AppsService";
import ConfiguratorVersionService from '@/modules/Apps/Services/Configurator/ConfiguratorVersionService'
import ConfiguratorStylesService from "@/modules/Apps/Services/Configurator/ConfiguratorStylesService";
import ConfiguratorHomeLayoutService from "@/modules/Apps/Services/Configurator/ConfiguratorHomeLayoutService";
import {Version} from "@/modules/Apps/Models/Version";
import {unifyPaginatedResponse} from "@/modules/Shared/Helpers/apiResponseHelper";
import {userHasAuthorization} from "@/modules/Auth/Helpers/drmHelper";
import ModalManageMenu from "@/modules/Apps/Components/configurator/ModalManageMenu.vue";
import emptyAlert from "@/modules/Shared/Components/alert/emptyAlert.vue";
import MenuTypeTabs from "@/modules/Apps/Components/configurator/MenuTypeTabs.vue";
import store from "@/modules/Shared/Store/store";
import ResourceService from "@/modules/Apps/Services/Configurator/ConfiguratorResourcesService";
import DatatablePageLayout from "@/modules/Shared/Pages/DatatablePageLayout";

export default {
    name: 'menu-list',
    components: {
        MenuTypeTabs,
        emptyAlert,
        DatatablePageLayout,
        ModalManageMenu
    },
    mixins: [loader],
    data() {
        return {
            version: null,
            versionModel: null,
            isAdmin: false,
        }
    },
    async beforeRouteEnter (to, from, next) {
        const version = await ConfiguratorVersionService.getVersion(to.params.versionUuid);
        await store.dispatch('setRouteElement', version)
        next(vm => vm.version = version)
    },
    methods: {
        addMenu(menu) {
            this.versionModel.menus.push(menu)
        }
    },
    async created() {
            await this.load(async () => {
            this.isAdmin = userHasAuthorization([{
                context: 'admin',
                drm: 'apps'
            }])

            const [application, languages, styles, layouts, menus, resources, customerResources] = await Promise.all([
                AppsService.basicAppInfo(this.$route.params.uuid),
                AppsService.getLanguages(this.$route.params.uuid),
                ConfiguratorStylesService.getStyleList(this.$route.params.versionUuid),
                ConfiguratorHomeLayoutService.getHomeLayoutList(PaginationService.parseParameters({
                    page: 1,
                    per_page: 30,
                    filters: [{ field: "version_uuid", value: this.$route.params.versionUuid }]
                })),
                unifyPaginatedResponse(ConfiguratorMenuService.getMenuList, PaginationService.parseParameters({
                    per_page: 30,
                    filters: [{ field: "version_uuid", value: this.$route.params.versionUuid }]
                })),
                ResourceService.getResources(PaginationService.parseParameters({
                    page: 1,
                    filters: [{ field: "version_uuid", value: this.$route.params.versionUuid }]
                })),
                ResourceService.getCustomerResources(this.$route.params.versionUuid)
            ])

            const [tabTemplates, homeTemplates, selectorTemplates, profileTemplates, ...stylesDetail] = await Promise.all([
                ...['tab', 'home', 'selector', 'profile'].map(type => unifyPaginatedResponse(ConfiguratorItemTemplateService.getItemTemplateList, PaginationService.parseParameters({
                    per_page: 30,
                    filters: [
                        {
                            field: "app_type",
                            value: application.data.object.features.find(({ key }) => key === "is_ski_app") ? 'ski' : 'parks and attractions'
                        },
                        {
                            field: "menu_type",
                            value: type
                        }
                    ]
                }))),
                ...styles.data.map(style => ConfiguratorStylesService.getValuesList(style.uuid))
            ])

            const templates = [
                ...tabTemplates.map(t => ({...t, menu_type: 'tab'})),
                ...homeTemplates.map(t => ({...t, menu_type: 'home'})),
                ...selectorTemplates.map(t => ({...t, menu_type: 'selector'})),
                ...profileTemplates.map(t => ({...t, menu_type: 'profile'}))
            ]

            this.versionModel = new Version(
                application.data.object,
                this.version,
                stylesDetail,
                layouts.data,
                menus.map(menu => ({
                    ...menu,
                    resortsNames: application.data.object.resorts
                        .filter(resort => menu.resorts.includes(resort.uuid))
                        .map(resort => resort.name)
                        .join(', '),
                })),
                languages,
                templates,
                resources.data[0].files,
                customerResources
            )
        })


    }
};
</script>
