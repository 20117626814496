var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("emptyAlert", {
        staticClass: "mb-4",
        attrs: { message: _vm.$t("apps.configurator.preview.disclaimer") }
      }),
      _c(
        "div",
        { staticClass: "flex justify-center items-center mb-4" },
        [
          _vm.languages.length > 1
            ? _c(
                "vs-select",
                {
                  staticClass: "border-grey-light w-full md:w-1/2",
                  attrs: { required: "" },
                  model: {
                    value: _vm.selectedLanguage,
                    callback: function($$v) {
                      _vm.selectedLanguage = $$v
                    },
                    expression: "selectedLanguage"
                  }
                },
                _vm._l(_vm.languages, function(language) {
                  return _c("vs-select-item", {
                    key: language.acronym,
                    attrs: { value: language.acronym, text: language.name }
                  })
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.loaded && _vm.content && _vm.layout && _vm.styles
        ? _c("MenuPreview", {
            attrs: {
              content: _vm.content,
              layout: _vm.layout,
              styles: _vm.styles,
              language: _vm.selectedLanguage,
              timeofyear: _vm.menu.timeofyear,
              type: _vm.menu.type
            }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "action-item-button-container vx-col w-full mt-8" },
        [
          _c(
            "button",
            {
              staticClass:
                "xs:w-full sm:w-auto rounded-lg sk-btn sk-btn-primary btn-border mr-2",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("cancel")
                }
              }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("apps.configurator.menu-list.cancel")) + " "
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }